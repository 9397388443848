<div class="flex flex-wrap relative">
    <div class="p-4 md:p-6 lg:p-12 flex-1 min-w-0 max-w-screen">
        <!-- <app-backgrounds></app-backgrounds> -->
        <div class="w-full relative z-10 mt-12 md:mt-0">
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-4">
                <div class="flex px-4 lg:px-6 w-full max-w-screen relative md:max-w-md xl:max-w-full">
                    <!--<span *ngIf="isRegion" class="">Region</span>
                    <span *ngIf="!isRegion" class="">Pin</span>-->
                    <div class="inline-flex justify-center items-center aspect-square w-9 h-9">
                        <img *ngIf="isRegion === true" src="assets/images/map-polygon-select-primary.png" class="flex-none w-5 h-5">
                        <img *ngIf="isRegion === false" src="assets/images/map-pin-select-primary.png" class="flex-none w-5 h-5">
                    </div>
                    <div *ngIf="showloadergraph" class="w-full flex-1 flex flex-nowrap justify-start items-center relative">
                        <span  maxlength="50" type="text" class="flex-grow cursor-pointer tracking-normal font-bold text-2xl">{{ title }}</span>
                    </div> 
                    <app-editable-title *ngIf="!showloadergraph" (changedTitle)="onChangedReportTitle($event)" [title]="title" [titleval]="title" [eventname]="eventDetails.event_name" class="flex-1"></app-editable-title>
                </div>
                <div class="fixed bottom top-0 left-0 md:left-auto right-0 px-4 pt-8 pb-0 mt-6 lg:px-12 lg:py-12 lg:mt-0 pointer-events-none z-50 xl:hidden">
                    <button (click)="toggleReportSidebar()" class="btn-gradient w-full text-center justify-center px-3 py-2 text-sm font-semibold pointer-events-auto inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2 fill-current stroke-current" viewBox="0 0 48 48"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(0.5 0.5)"><circle cx="24" cy="24" r="7" fill="none" stroke-miterlimit="10"></circle> <path d="M46,24h0a3.26,3.26,0,0,0-2.99-3.249L39.6,20.466a15.89,15.89,0,0,0-2.072-4.991l2.217-2.62a3.26,3.26,0,0,0-.183-4.411h0a3.26,3.26,0,0,0-4.411-.183l-2.62,2.217A15.876,15.876,0,0,0,27.534,8.4L27.249,4.99A3.26,3.26,0,0,0,24,2h0a3.26,3.26,0,0,0-3.249,2.99L20.466,8.4a15.89,15.89,0,0,0-4.991,2.072L12.855,8.26a3.26,3.26,0,0,0-4.411.183h0a3.26,3.26,0,0,0-.183,4.411l2.217,2.62A15.876,15.876,0,0,0,8.4,20.466l-3.415.285A3.26,3.26,0,0,0,2,24H2a3.26,3.26,0,0,0,2.99,3.249l3.415.285a15.89,15.89,0,0,0,2.072,4.991L8.26,35.145a3.26,3.26,0,0,0,.183,4.411h0a3.26,3.26,0,0,0,4.411.183l2.62-2.217A15.876,15.876,0,0,0,20.466,39.6l.285,3.415A3.26,3.26,0,0,0,24,46h0a3.26,3.26,0,0,0,3.249-2.99l.285-3.415a15.89,15.89,0,0,0,4.991-2.072l2.62,2.217a3.26,3.26,0,0,0,4.411-.183h0a3.26,3.26,0,0,0,.183-4.411l-2.217-2.62A15.876,15.876,0,0,0,39.6,27.534l3.415-.285A3.26,3.26,0,0,0,46,24Z" fill="none" stroke-miterlimit="10"></path></g></svg>
                        Customise report view
                    </button>
                </div>
            </div>
            <div class="flex items-center mb-5">
                <div *ngFor="let typeWeather of selectedTypes">
                    <div *ngIf="typeWeather === currentType" class="w-12 h-12">
                        <app-weather-type-small [type]="typeWeather"></app-weather-type-small>
                    </div>
                </div>
                <div class="w-full flex flex-nowrap justify-start items-center relative tooltipwrp">
                    <div class="w-full sm:w-3/5">
                        <h1 class="w-full font-semibold ml-3 leading-tight" [innerHTML]="currentTitleSelected"></h1>
                        <div *ngIf="isStarted" class="text-sm font-medium italic text-orange-light ml-3 date-warning" [ngClass]="{'text-red-alert': isEnded, 'text-orange-light': !isEnded && isStarted}">
                            {{ isEnded ? 'This event period has ended' : isStarted ? 'This event period has started' : '' }}
                        </div>
                    </div>
                    <div class="w-full sm:w-2/5 text-right hidden sm:block">
                        <h1 class="w-full font-semibold leading-tight" [innerHTML]="currentModelSelected"></h1>
                    </div>
                    <!--<div class="tooltip enqry">
                        <p [innerHTML]="modelDefText"></p>
                    </div>-->
                </div>
            </div>
            <div class="w-full text-left block sm:hidden mt-2 mb-1">
                <h1 class="w-full text-sm font-semibold leading-tight" [innerHTML]="currentModelSelected"></h1>
            </div>         
            <div class="flex flex-wrap -mx-4 lg:-mx-6">
                <div class="w-full px-4 lg:px-6">
                    <div class="bg-white p-6 border border-gray-300 rounded-lg mb-4">
                        <ng-container>
                            <div class="modLoader" *ngIf="showloaderoverview">
                                <div class="ldWr">
                                    <span class="cmnLoader"></span>
                                    <label>{{loaderhtimeoverview}}%</label>
                                </div>                                
                            </div>
                            <div class="overview-panel" *ngIf="!showloaderoverview">
                                <app-results-numeric-value *ngIf="type=='risk' && currentType == 'wildfire' && overviewRiskdataResponse != undefined && overviewRiskdataResponse != ''" [overviewRiskdata]="overviewRiskdataResponse" [riskName]="currRiskData.itemName" class="w-full mb-4 block"></app-results-numeric-value>
                                <app-results-numeric-values *ngIf="type=='risk' && currentType != 'wildfire' && overviewRiskdataResponse != undefined && overviewRiskdataResponse != ''" [overviewRiskdata]="overviewRiskdataResponse" [riskName]="currRiskData.itemName" [riskMode]="riskMode" class="w-full mb-4 block"></app-results-numeric-values>

                                <app-results-overview-data [hourList]="[hourlySelected, startHours, endHours]"  *ngIf="type!='risk' && overviewPythonData != undefined && overviewPythonData != ''" [searchState]="searchState" class="w-full mb-6 block"></app-results-overview-data>
                            </div>
                        </ng-container>
                        
                        <div class="w-full mb-4">                            
                            
                            <ng-container>
                                <div class="modLoader border-t border-gray" *ngIf="showloadergraph">
                                    <div class="ldWr">
                                        <span class="cmnLoader"></span>
                                        <label>{{loaderhtimegraph}}%</label>                                        
                                    </div>                                
                                </div>
                                <div class="graph-panel" *ngIf="!showloadergraph">
                                    <div (clickOutside)="hideDropDown()" class="dropdown relative inline-block mb-3">
                                        <button (click)="riskDropdown()" class="cursor-pointer tracking-wider uppercase font-semibold text-sm text-gray-600">
                                            {{showAdvancedType}}
                                            <svg xmlns="http://www.w3.org/2000/svg" class="arrow-button-change inline-block ml-3 fill-current stroke-current" viewBox="0 0 12 12"><g stroke-width="1"><polyline points="0.5 4.5 6 8.5 11.5 4.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline></g></svg>
                                        </button>
                                        <div *ngIf="showHideRisk" class="absolute dropdown-content">
                                            <div class="tippy-arrow absolute left-4"></div>
                                            <a (click)="onChangeViewAdvanced('graph')" class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600" href="javascript:void(0)">Graph</a>
                                            <a *ngIf="!(isRegion && ['wildfire', 'tornado', 'earthquake', 'hurricane'].includes(currentType)) && currentType != 'wildfire'" (click)="onChangeViewAdvanced('table')" class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600" href="javascript:void(0)">Table</a>
                                            <a *ngIf="!(isRegion && ['wildfire', 'tornado', 'earthquake', 'hurricane'].includes(currentType)) && (type == 'risk' && (currentType != 'wildfire' && (hurricaneCurrentModelName !='Advanced Risk' && currentType == 'hurricane' && userSector==2)) || ((currentType != 'hurricane' || userSector !=2) && currentType != 'wildfire' && type == 'risk'))" (click)="onChangeViewAdvanced('map')" class="px-10 py-3 text-sm font-semibold text-primary w-full block border-gray-300 hover:text-gray-600" href="javascript:void(0)">Map</a>
                                            <!--Remove !(isRegion && ['wildfire', 'tornado', 'earthquake', 'hurricane'].includes(currentType)) conditions-->
                                        </div>
                                    </div> 
                                    <app-results-vertical-bars-stack *ngIf="type=='risk' && showAdvancedType === 'graph' && fetchedRiskData != undefined && fetchedRiskData !=''" [riskGraphData]="riskDataToBePassed" [riskType]="currentType" [hurricaneCategoryLabel]="hurricaneCategoryLabel" (onNextPreviousRisk)="getNextPreviousRiskGraphData($event)" class="w-full mb-4 block"></app-results-vertical-bars-stack>
                                
                                    <app-results-table-risk *ngIf="type=='risk' && showAdvancedType === 'table' && fetchedRiskData != undefined && fetchedRiskData != ''" [tableRiskData]="tableRiskData" [riskType]="currentType" [riskMode]="riskMode" [risktableHead]="risktableHead" [more]="riskDataToBePassed.more" [currPage]="riskDataToBePassed.page" [hurricaneCategoryLabel]="hurricaneCategoryLabel" (onNextPreviousRisk)="getNextPreviousRiskGraphData($event)" class="w-full mb-4 block"></app-results-table-risk>
                                    <app-results-map *ngIf="type=='risk' && showAdvancedType === 'map' && riskImageData != undefined && riskImageData != ''" [imgPath]="imgPath" [weekNo]="weekNo" [currentType]="currentType" [riskImgNames]="riskImgNames" (onNextPrevMap)="getNextPrevRiskMapData($event)" [showleftScroll]="showleftScroll" [showRightScroll]="showRightScroll" [riskindex]="riskindex" class="w-full mb-4 block"></app-results-map>
        
                                    <app-results-table-data  [searchState]="searchState" [weatherid]="curentWeatherVarData.weathertypeid" [hourlytype] = 'hourlySelected' [tableData]="tableData" [avarageData]="riskSummary" [graphpage]="graphpage" (selectDate)="loadGraphOnDate($event)" (changegraphpage)="nextprevgraph($event)" *ngIf="type=='stats' && showAdvancedType === 'table' && showGraph && searchState" [shownext] ="showNext" class="w-full block"></app-results-table-data>
                                    <app-results-horizontal-graph-data (selectDate)="loadGraphOnDate($event)"  [searchState]="searchState" [hourlytype] = 'hourlySelected' [weatherName]="currentType" [graphData]="graphdata" (changegraphpage)="nextprevgraph($event)" [graphpage]="graphpage" [shownext] ="showNext"  *ngIf="type=='stats' && showAdvancedType === 'graph' && showGraph && searchState" class="w-full block"></app-results-horizontal-graph-data>
                                    <app-results-table-advanced *ngIf="type=='predict' && graphId== 2 && showAdvancedType === 'table' && showGraph && plotlyGraphData != undefined && plotlyGraphData != ''" [predicttableData]="predicttableData" [fetchedpredictData]="fetchedpredictData" (onNextPrevious)="getNextPreviousPlotlyData($event)" class="w-full mb-4 block"></app-results-table-advanced>
                                    <app-results-graph-plot *ngIf="type=='predict' && graphId== 2 && showAdvancedType === 'graph' && showGraph && plotlyGraphData != undefined && plotlyGraphData != ''" [predGraphData]="plotlyGraphData" [plotlyGraphLayout]="plotlyGraphLayout" [fetchedpredictData]="fetchedpredictData" (onNextPrevious)="getNextPreviousPlotlyData($event)" class="w-full mb-4 block"></app-results-graph-plot>
                                </div>
                            </ng-container>
                            
                        </div>
                    </div>
                    <div *ngIf="showloadergraph" style="pointer-events:none;color: rgb(151, 149, 149);">
                        <span class="border border-gray-300 rounded-lg px-8 py-4 flex items-center justify-center w-full bg-transparent bg-white hover:border-gray-500">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 mr-4" viewBox="0 0 29 19.333"><g data-name="Group 79"><path fill="#2b343b" d="M22.354 19.333h-3.021a.604.604 0 110-1.208h3.021a5.4375 5.4375 0 100-10.875h-1.812a.6.6 0 01-.6-.6 5.4375 5.4375 0 00-10.875 0 .6.6 0 01-.6.6H6.646a5.4375 5.4375 0 100 10.875h3.021a.604.604 0 010 1.208H6.646a6.646 6.646 0 110-13.292h1.235a6.647 6.647 0 0113.238 0h1.235a6.646 6.646 0 110 13.292z" data-name="Path 106"/></g><g data-name="Group 81"><g data-name="Group 80"><path fill="#2b343b" d="M14.5 19.333a.607.607 0 01-.428-.176l-3.625-3.625a.60387.60387 0 01.854-.854l3.2 3.2 3.2-3.2a.60387.60387 0 11.854.854l-3.625 3.625a.6.6 0 01-.43.176z" data-name="Path 107"/></g></g><g data-name="Group 82"><path fill="#2b343b" d="M14.496 19.334a.6.6 0 01-.6-.6V6.642a.604.604 0 011.208 0V18.73a.6.6 0 01-.608.604z" data-name="Path 108"/></g></svg>
                            <span class="font-semibold text-sm" *ngIf="hourlySelected=='hourly'">Download report in XLSX</span>
                            <span class="font-semibold text-sm" *ngIf="hourlySelected !='hourly'">Download report in PDF{{type=='stats'?'/XLSX':''}}</span>
                        </span>
                    </div>
                    <app-results-download-report-pdf *ngIf="!showloadergraph" [hourlytype] = 'hourlySelected' (downloadcombinedpdfaszip)="downloadreportzip();" [modelname]="type" class="w-full mb-4 block"></app-results-download-report-pdf>
                    <app-calendar-results *ngIf="showcal && type!='risk'" [weatherName]="currentType" [calmonths]="calmonths" [eventData]="eventRes" [thresholdSev]="getThresholdsData.severe" [thresholdMod]="getThresholdsData.alert"  class="w-full mb-4 block"></app-calendar-results>
                </div>

            </div>

        </div>
    </div>
    <div class="sidebar-report bg-gray-200 xl:rounded-tl-xl xl:min-h-screen max-w-md w-full xl:w-2/5" [class.active]="showSidebarMobile">
        <div [ngClass]="{'disabled' : showloadergraph}" class="sidebar-report__inner pb-6 pt-8 px-3 lg:px-4 xl:py-12 xl:px-8 w-full text-center relative xl:sticky top-0 min-h-screen flex flex-col">
            <div class="absolute top-0 right-0 p-4 xl:hidden">
                <button class="w-10 shadow hover:shadow-lg h-10 flex items-center bg-white rounded-full" (click)="toggleReportSidebar()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mx-auto fill-current stroke-current" width="64" height="64" viewBox="0 0 64 64"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(0.5 0.5)"><line x1="51.092" y1="12.908" x2="12.908" y2="51.092" fill="none" stroke-miterlimit="10"></line><line x1="51.092" y1="51.092" x2="12.908" y2="12.908" fill="none" stroke-miterlimit="10"></line></g></svg>
                </button>
            </div>
            <div class="w-full mb-4">
                <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold" *ngIf="numberInsights > 1 && unlimited != 1">{{ numberInsights }} insights</div>
                <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold" *ngIf="numberInsights <= 1 && unlimited != 1">{{ numberInsights }} insight</div>
            </div>
            <h3 class="text-primary font-semibold text-2xl">Customise your report</h3>
            <div class="my-4">
                <h5 class="text-primary text-sm font-semibold mb-3">Report view</h5>
                <div class="flex-1 flex flex-wrap justify-center items-center">
                    <div class="inline-flex mr-3 mb-1/2" *ngFor="let typeWeather of selectedTypes">
                        <button #tooltipWeatherElem (click)="onClickTypeWeather(typeWeather)" class="w-12 h-12 opacity-50 hover:opacity-75" *ngIf="typeWeather !== currentType" attr.data-type="{{ typeWeather }}">
                            <app-weather-type-small [type]="typeWeather"></app-weather-type-small>
                        </button>
                        <div *ngIf="typeWeather === currentType" class="w-16 h-16">
                            <app-weather-type-small [type]="typeWeather"></app-weather-type-small>
                        </div>
                    </div>      
                    <button #tooltipAddWeatherType *ngIf="(selectedTypes.length < countselectedwtr) && eventDetails.dayasdiff>0" (click)="onClickAddTypeWeather()" class="ml-3 rounded-full w-8 h-8 text-gray-800 shadow hover:shadow-lg bg-white inline-flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current fill-current h-3 w-3" viewBox="0 0 12 12"><g stroke-width="1"><line x1="6" y1="1" x2="6" y2="11" fill="none" stroke-linecap="round" stroke-linejoin="round"></line> <line x1="1" y1="6" x2="11" y2="6" fill="none" stroke-linecap="round" stroke-linejoin="round"></line></g></svg>
                    </button>
                </div>
                

                <div *ngIf="type != 'risk'" class="report_dropdown">
                    <div class="dropdown w-full relative">
                        <button (click)="toogleDropdown()"
                            class="mx-auto py-3 px-5 rounded-lg shadow-md bg-white mt-4 cursor-pointer tracking-wider uppercase font-semibold text-xs text-gray-600 flex w-full justify-between max-w-xs items-center">{{currentModelName}}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="arrow-button-change ml-3 fill-current stroke-current" viewBox="0 0 12 12">
                                <g stroke-width="1">
                                    <polyline points="0.5 4.5 6 8.5 11.5 4.5" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round"></polyline>
                                </g>
                            </svg>
                        </button>
                        <div *ngIf="showHideDropdown" id="myDropdown" class="dropdownmenu">
                            <div class="tippy-box">
                                <div class="tippy-content">
                                    <a (click)="changeModelData(modelItem)" *ngFor="let modelItem of modeldatashow(curentWeatherData);" class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-standard" href="javascript:void(0)">{{modelItem.model_name}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div *ngIf="currentType == 'hurricane' && userSector==2" class="report_dropdown_hurricane">
                    <div class="dropdown w-full relative">
                        <button (click)="toogleDropdownHurricane()"
                            class="mx-auto py-3 px-5 rounded-lg shadow-md bg-white mt-4 cursor-pointer tracking-wider uppercase font-semibold text-xs text-gray-600 flex w-full justify-between max-w-xs items-center">{{hurricaneCurrentModelName?hurricaneCurrentModelName:'Standard Risk'}}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="arrow-button-change ml-3 fill-current stroke-current" viewBox="0 0 12 12">
                                <g stroke-width="1">
                                    <polyline points="0.5 4.5 6 8.5 11.5 4.5" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round"></polyline>
                                </g>
                            </svg>
                        </button>
                        <div *ngIf="showHideDropdownHurricane" id="myDropdown" class="dropdownmenu">
                            <div class="tippy-box">
                                <div class="tippy-content">
                                    <a (click)="changeModelDataHurricane(modelItem)" *ngFor="let modelItem of hurricaneModels;" class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-standard" href="javascript:void(0)">{{modelItem}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div *ngIf="currentType == 'wildfire'" class="report_dropdown_wildfire">
                    <div class="dropdown w-full relative">
                        <button (click)="toogleDropdownWildfire()"
                            class="mx-auto py-3 px-5 rounded-lg shadow-md bg-white mt-4 cursor-pointer tracking-wider uppercase font-semibold text-xs text-gray-600 flex w-full justify-between max-w-xs items-center">{{wildfireCurrentModelName ? wildfireCurrentModelName : 'Advanced Predictive' }}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="arrow-button-change ml-3 fill-current stroke-current" viewBox="0 0 12 12">
                                <g stroke-width="1">
                                    <polyline points="0.5 4.5 6 8.5 11.5 4.5" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round"></polyline>
                                </g>
                            </svg>
                        </button>
                        <div *ngIf="showHideDropdownWildfire" id="myDropdown" class="dropdownmenu">
                            <div class="tippy-box">
                                <div class="tippy-content">
                                    <a (click)="changeModelDataWildfire(modelItem)" *ngFor="let modelItem of wildfireModels;" class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-standard" href="javascript:void(0)">{{modelItem}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-auto mt-6" *ngIf="modelnameonly == 'Standard' && type != 'risk'">
                    <app-button-toggle (toggle)="onAmountDataClick(item, 1);" *ngFor="let item of currentModelData.duration;" [dataActive]='amountData === item.name' class="ml-2 w-auto">{{item.name}}</app-button-toggle>
                </div>
                <!-- Risk mode and year drop-downs will be placed here -->
                <ng-template #showotherriskdata>
                    <div *ngIf="type == 'risk' && currentType != 'wildfire'" class="w-auto mt-6">
                        <app-button-toggle (toggle)="onRiskYearClick(year, 1);" *ngFor="let year of currRiskData.year;" [dataActive]='riskYear === year.itemName' class="ml-2 w-auto">{{year.itemName}}</app-button-toggle>
                    </div>
                    <div *ngIf="type == 'risk'" class="w-auto mt-6">
                        <ng-container *ngIf="!(isRegion && isNatCat)">
                            <app-button-toggle (toggle)="onRiskModeClick(mode, 1);" *ngFor="let mode of currRiskData.mode;" [dataActive]='riskMode === mode.itemName' class="ml-2 w-auto">{{mode.itemName}}</app-button-toggle>
                        </ng-container>
                    </div>
                </ng-template>
               
                
               <div *ngIf="hurricaneCurrentModelName =='Advanced Risk' && type == 'risk' && currentType == 'hurricane' && userSector==2; else showotherriskdata">
                    <div class="w-auto mt-6">
                        <app-button-toggle  class="ml-2 w-auto" [dataActive]='true'>All Data</app-button-toggle>
                    </div>
                    <div class="w-auto mt-6">
                        <app-button-toggle  class="ml-2 w-auto" [dataActive]='true'>Percentage</app-button-toggle>
                    </div>
                </div>

                <!-- Risk mode and year drop-downs will be placed here -->

            </div>

            <div *ngIf="type!='risk' && ['GB','US'].includes(eventDetails.country)"  class="my-4 text-center mx-auto">
                <h5 class="text-primary text-sm font-semibold mb-3">Time resolution</h5>
                <button  *ngFor="let item of timeresolusion">
                    <button #tooltipdishourly *ngIf="item.value=='hourly' && !isHourlyAvailable">
                        <app-button-toggle  (toggle)="onResolutionClick(item.value);"  [dataActive]='hourlySelected==item.value' [dataDeactive]="!isHourlyAvailable" class="mx-2 mb-2 w-auto inline-flex">{{ item.name }}</app-button-toggle>
                    </button>
                    <button *ngIf="item.value=='hourly' && isHourlyAvailable">
                        <app-button-toggle  (toggle)="onResolutionClick(item.value);"  [dataActive]='hourlySelected==item.value' [dataDeactive]="" class="mx-2 mb-2 w-auto inline-flex">{{ item.name }}</app-button-toggle>
                    </button>
                    <button *ngIf="item.value !='hourly'">
                        <app-button-toggle  (toggle)="onResolutionClick(item.value);"  [dataActive]='hourlySelected==item.value' [dataDeactive]="" class="mx-2 mb-2 w-auto inline-flex">{{ item.name }}</app-button-toggle>
                    </button>
                </button>
            </div>

            <!--<div class="{{hourlySelected === 'hourly' ? 'border border-white' : ''}}  rounded-sm p-5 xl:px-8">-->
            <div class="{{hourlySelected === 'hourly' ? 'border border-white' : ''}}  rounded-sm py-5">

                <div class="max-w-xs mx-auto">
                    <h5 class="text-primary text-sm font-semibold mb-3">Customise your dates</h5>
    
                    <form class="w-full block sidebar mx-auto max-w-xs" autocomplete="off">
                        <div #containerDates class="shadow-md bg-white rounded-lg border border-gray-300 flex flex-wrap relative mt-4">
                            <div class="flex-1 border-r border-gray-300">
                                <label class="py-3 px-4 block cursor-pointer">
                                    <div>
                                        <div class="text-xs uppercase font-bold text-gray-600 text-left">Start date</div>
                                        <input #dateStart type="text" readonly="true" name="startDate" class="w-full outline-none cursor-pointer placeholder-gray-800 text-gray-800 truncate" placeholder="Add start date" />
                                    </div>
                                </label>
                            </div>
                            <div class="flex-1">
                                <label class="py-3 px-4 block cursor-pointer">
                                    <div>
                                        <div class="text-xs uppercase font-bold text-gray-600 text-left">End date</div>
                                        <input #dateEnd type="text" readonly="true" name="endDate" class=" w-full outline-none cursor-pointer placeholder-gray-800 text-gray-800 truncate" placeholder="Add end date" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </form>
    
                    <!--<div class="text-sm italic text-gray-700 mt-2">Available from {{ eventDetails.startDate }} to {{ eventDetails.endDate }}</div>-->
                </div>
                
                <div *ngIf="type!='risk' && ['GB','US'].includes(eventDetails.country) && hourlySelected=='hourly'" class="mt-3 max-w-xs mx-auto">
                    <h5 class="text-primary text-sm font-semibold mb-3">Customise your times</h5>
                    <div class="shadow-md bg-white rounded-lg border border-gray-300 flex flex-wrap relative mt-4">
                        <div class="w-1/2 border-r border-gray-300 justify-center">
                            <label class="py-3 px-4 block cursor-pointer">
                                <div>
                                    <div class="text-xs uppercase font-bold text-gray-600 text-center whitespace-nowrap">Start time</div>
                                    <select class="bg-white p-1 border-0" name="start-time" #starthours (change)="changehours(starthours.value,'start')">
                                        <option class="border-0" [value]="hour.value" *ngFor="let hour of hourslist" [selected]="hour.value==startHours">{{hour.name}}</option></select>                                </div>
                            </label>
                        </div>
                        <div class="w-1/2 justify-center">
                            <label class="py-3 px-4 block cursor-pointer">
                                <div>
                                    <div class="text-xs uppercase font-bold text-gray-600 text-center whitespace-nowrap">End time</div>
                                    <select class="bg-white p-1 border-0" name="end-time" #endhours (change)="changehours(endhours.value, 'end')">
                                        <option class="border-0" [value]="hour.value" *ngFor="let hour of hourslist" [selected]="hour.value==endHours">{{hour.name}}</option>
                                        </select>
                                </div>
                            </label>
                        </div>
                    </div>    
    
                    <div class="text-sm italic text-gray-700 mt-2">In local time for your search location</div>
                </div>

                <button *ngIf="showupdatebutton" class="text-sm font-semibold text-white block w-full max-w-[313px] py-2 px-4 rounded-full btn-gradient mt-4" (click)="updatetimedate()" type="button">Apply</button>

            </div>

            <div *ngIf="type!='risk'" class="my-4 text-center mx-auto">
                <h5 class="text-primary text-sm font-semibold mb-3">Results type</h5>
                <div class="w-full flex-1 justify-center items-center">
                    <app-button-toggle (toggle)="pageaccorddate(graphpage);onHeadSelectClick(item,1);" *ngFor="let item of filterOhurlyHead(currentModelData.weathers);" [dataActive]='item.active' class="mx-2 mb-2 w-auto inline-flex">{{ item.displayName }}</app-button-toggle>
                </div>
            </div>

            <div *ngIf="modelType!='S' && modelType!='G' && currentType != 'rain' && type=='predict'" class="my-4 text-center mx-auto">
                <h5 class="text-primary text-sm font-semibold mb-3">Prediciton period</h5>
                <div class="w-full flex-1 justify-center items-center">
                    <app-button-toggle (toggle)="onFreqSelectClick(freq)" *ngFor="let freq of predFrequency;" [dataActive]='freq.active' class="mx-2 mb-2 w-auto inline-flex">{{ freq.name }}</app-button-toggle>
                </div>
            </div>

            <!--<div class="my-4 mx-auto" *ngIf="currentType === 'rain' || currentType === 'wind' || currentType === 'temperature' || currentType === 'snow'"></div>-->
            <div class="my-4" *ngIf="currentType === 'rain' || currentType === 'wind' || currentType === 'temperature' || currentType === 'snow'">
                <h5 class="text-primary text-sm font-semibold mb-3">Select thresholds</h5>
                <app-thresholds-item [notify]="notify" *ngIf="getThresholdsData != undefined && getThresholdsData != '' && this.getThresholdsData.unitData != undefined && this.getThresholdsData.unitData != ''" class="w-full" [type]="currentType" [thresholdsData]="getThresholdsData" (onUpdateThresholds)="getPythonOverviewDataWithUpdatedThresholds($event)" (onUnitConvert)="saveUnitConversionData($event)" (onThresholdsChangeMessage)="showThresholdsMessage($event)"></app-thresholds-item>
            </div>

            <div *ngIf="type!='risk' && stationName != undefined && stationName != ''" class="mx-auto mt-auto text-left">
                <div class="text-sm"><span class="inline-block w-16 text-right font-semibold">Station</span>  -  {{stationName}}</div>
                <div *ngIf="stationHeight != undefined && stationHeight != ''" class="text-sm"><span class="inline-block w-16 text-right font-semibold">{{ isRegion ? 'Av height' : 'Height' }}</span>  -  {{stationHeight}}</div>
                <div *ngIf="stationDistance != undefined && stationDistance != ''" class="text-sm"><span class="inline-block w-16 text-right font-semibold">Distance</span>  -  {{stationDistance}}</div>
            </div>
             

        </div>
    </div>
</div>
<div class="overlay-sidebar" (click)="toggleReportSidebar()" [class.active]="showSidebarMobile"></div>
<div *ngIf="isError" class="col-12 watchevent">
    <div class="watcheventwrp commonclr">
      <a (click)="closeOverlay();"  class="closewatch">
        <!--<img src="../assets/images/close-popup.png" alt="">-->
        <svg _ngcontent-wbu-c48="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer inline-block float-right">
            <path _ngcontent-wbu-c48="" stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"></path>
        </svg>
      </a>
      <div class="eventcost">
        <h3 [innerHTML]="errorMsg"></h3>
      </div>
    </div>
</div>

<div *ngIf="closepopup" class="col-12 watchevent">
    <div class="watcheventwrp commonclr">
      <a (click)="closeOverlaynew();"  class="closewatch">
        <img src="../assets/images/close-popup.png" alt="">
      </a>
      <div class="eventcost">
        <h3>Your requested reports are being collated and will be sent to your account's email address shortly.
            Please contact support@metswift.com if you have not received them within an hour.</h3>
      </div>
    </div>
</div>

<div *ngIf="hourvalidate" class="col-12 watchevent">
    <div class="watcheventwrp commonclr">
      <a (click)="closeOverlaynew();"  class="closewatch">
        <img src="../assets/images/close-popup.png" alt="">
      </a>
      <div class="eventcost">
        <h3>End Hours should not be earlier than Start Hours.</h3>
      </div>
    </div>
</div>
