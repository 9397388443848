<div class="lg:flex-grow p-4 lg:p-6 xl:p-12 relative">
    <app-backgrounds></app-backgrounds>
    <div class="w-full relative my-portfolio z-10">
        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-4 lg:mb-6">
            <div class="flex px-4 lg:px-6 w-auto lg:w-1/2">
                <h1 class="font-semibold text-xl lg:text-2xl" #clickonbody>{{ titleField }}</h1>
                <div (click)="onClickSharePortfolio()" class="flex flex-none w-8 h-8 ml-1 ml-4 rounded-full btn-gradient items-center justify-center text-white font-light cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-5 h-5 py-px">
                        <path stroke-linecap="round" stroke-linejoin="round"  d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14"/>
                    </svg>                                     
                </div>  
                <div *ngIf="portfolioSharedWithUsers && portfolioSharedWithUsers.length > 0" class="flex items-start">
                    <span class="ml-8 mt-2 text-sm leading-relaxed">Shared with </span>
                    <ul class="flex flex-row ml-4">
                        <li class="w-8 h-8 btn-light-gray-gradient -mx-1 list-none rounded-full" *ngFor="let user of portfolioSharedWithUsers; let i = index" [ngStyle]="{ 'z-index': -i }">
                            <span class="inline-flex font-medium w-full h-full mr-2 text-xs border-0.5 border-white justify-center items-center rounded-full" [ngClass]="['user-icon' + ('-' + organisationUsers[user.id].styleNum || '')]">
                                {{ user.initials }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
                <app-search-reports-button></app-search-reports-button>
            </div>
        </div>
        <div class="flex flex-wrap mx-4 lg:-mx-6">
            <div *ngIf="showSearchEvent" class="w-full mb-4 px-4 lg:px-6">
                <p class="inline-block bg-gray-300 font-bold text-black rounded-full px-5 py-1">{{filterObj.eventnames}}</p>
                <button type="button" class="ml-2" (click)="removesearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="12" height="12" viewBox="0 0 16 16"><g><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></g></svg>
                </button>
            </div>
        </div>

        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3">
            <div *ngIf="showFilter" class="w-full mb-3 px-4 lg:px-6 mt-4">
                <div class="w-full block p-4 lg:rounded-lg bg-gray-200">
                    <div class="flex flex-wrap h-8 mb-4 pt-1 px-1">
                        <h3 class="w-1/2 text-primary text-sm font-semibold">Available filters</h3>
                        <div class="w-1/2 text-right">
                            <button *ngIf="isFiltered" class="button-filter text-primarylighter text-center border border-gray-400" (click)="resetRemoveFilter('all')">Clear Filters</button>
                            <!--<button *ngIf="showCountries||showStates||showWeathers||showRisks||showMonths||showUsers" class="button-filter text-center btn-gradient" [ngStyle]="{'background':(buttoncolor=='orange'?'linear-gradient(to right, #EB8B38 0%, #E46B22 50%, #DD4E0E 100%)':'')}" [style.color]="buttoncolor=='orange'?'white':''" [style.font-weight]="buttoncolor=='orange'?'normal':'bold'" (click)="fetchFilters(1)">Apply Filters</button>-->
                            <button *ngIf="showApplyBtn" class="button-filter ml-4 text-white text-center btn-gradient" (click)="fetchFilters(1)">Apply Filters</button>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3">
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 flex items-center px-3 my-2">
                            <div class="flex-1 block relative filter-button" [ngClass]="{ 'applied-filter': isFilteredByCountries, 'multi-select': selectedCountriesFilterItems.length > 1  }">
                                <angular2-multiselect (click)="feedData('c')" [data]="countriesFilterList" [(ngModel)]="selectedCountriesFilterItems"
                                    [settings]="countriesSettings"
                                    (onSelect)="onItemSelectDeselect($event,'c',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'c',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'c',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'c',0); resetRemoveFilter('c')">
                                </angular2-multiselect>
                            </div>
                            <!--<button (click)="resetRemoveFilter('c')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>-->
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 flex items-center px-3 my-2">
                            <div class="flex-1 block relative filter-button" [ngClass]="{ 'applied-filter': isFilteredByStates, 'multi-select': selectedStatesFilterItems.length > 1 }">
                                <angular2-multiselect (click)="feedData('s')" [data]="statesFilterList" [(ngModel)]="selectedStatesFilterItems"
                                    [settings]="statesSettings"
                                    (onSelect)="onItemSelectDeselect($event,'s',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'s',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'s',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'s',0); resetRemoveFilter('s')">
                                </angular2-multiselect>
                            </div>
                            <!--<button (click)="resetRemoveFilter('s')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>-->
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 flex items-center px-3 my-2">
                            <div class="flex-1 block relative filter-button" [ngClass]="{ 'applied-filter': isFilteredByWeathers, 'multi-select': selectedWeathersFilterItems.length > 1 }">
                                <angular2-multiselect (click)="feedData('w')" [data]="weathersFilterList" [(ngModel)]="selectedWeathersFilterItems"
                                    [settings]="weathersSettings"
                                    (onSelect)="onItemSelectDeselect($event,'w',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'w',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'w',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'w',0); resetRemoveFilter('w')">
                                </angular2-multiselect>
                            </div>
                            <!--<button (click)="resetRemoveFilter('w')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>-->
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 flex items-center px-3 my-2">
                            <div class="flex-1 block relative filter-button" [ngClass]="{ 'applied-filter': isFilteredByRisks, 'multi-select': selectedRisksFilterItems.length > 1 }">
                                <angular2-multiselect (click)="feedData('ri')" [data]="risksFilterList" [(ngModel)]="selectedRisksFilterItems"
                                    [settings]="risksSettings"
                                    (onSelect)="onItemSelectDeselect($event,'ri',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'ri',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'ri',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'ri',0); resetRemoveFilter('ri')">
                                </angular2-multiselect>
                            </div>
                            <!--<button (click)="resetRemoveFilter('ri')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>-->
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 flex items-center px-3 my-2">
                            <div class="flex-1 block relative filter-button" [ngClass]="{ 'applied-filter': isFilteredByMonths, 'multi-select': selectedMonthsFilterItems.length > 1 }">
                                <angular2-multiselect (click)="feedData('mo')" [data]="monthsFilterList" [(ngModel)]="selectedMonthsFilterItems"
                                    [settings]="monthsSettings"
                                    (onSelect)="onItemSelectDeselect($event,'mo',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'mo',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'mo',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'mo',0); resetRemoveFilter('mo')">
                                </angular2-multiselect>
                            </div>
                            <!--<button (click)="resetRemoveFilter('mo')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>-->
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 flex items-center px-3 my-2">
                            <div class="flex-1 block relative filter-button" [ngClass]="{ 'applied-filter': isFilteredByUsers, 'multi-select': selectedUsersFilterItems.length > 1 }">
                                <angular2-multiselect (click)="feedData('u')" [data]="usersFilterList" [(ngModel)]="selectedUsersFilterItems"
                                    [settings]="usersSettings"
                                    (onSelect)="onItemSelectDeselect($event,'u',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'u',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'u',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'u',0); resetRemoveFilter('u')">
                                </angular2-multiselect>
                            </div>
                            <!--<button (click)="resetRemoveFilter('u')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>-->
                        </div>


                    </div>
                </div>
            </div>
        </div>



        <table class="w-full block relative overflow-hidden border border-gray-300 lg:border-0 rounded mb-5">
            <thead class="header-table bg-primary text-white">
                <tr *ngFor="let i of [].constructor(10)" class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                    <th class="header-table__elem max-w-xxxs text-center text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">User</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Report Id</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Name</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Weather Types / Risks</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Date Range</th>
                    <th class="header-table__elem w-1/4 text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Location</th>
                    <th class="header-table__elem min-w-xs max-w-xxxs actions-column text-center text-white font-light border-r border-primary px-2 xl:px-3 py-1 lg:py-2">Actions</th>
                </tr>
            </thead>
            <tbody #bodyTable class="block lg:table-row-group flex-1 lg:flex-none bg-white border-gray-300 lg:border-t-0 lg:border rounded rounded-t-none">
                <tr *ngIf="resultData.length < 1" class="flex flex-col flex-no-wrap border-b-none border-gray-300 lg:table-row mb-0 lg:mb-0">
                    <td colspan="7" class="text-sm text-center border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                       <b> {{(showSearchEvent||showGroups||showEvents||showWeathers||showDecision||showStatus||showRsi||showCostitems||showMonths||showStates||showCountries||showPostcodes||showModels||showRisk)?"No events found matching search criteria":"No data found"}}</b>
                    </td>
                </tr>
                <ng-template ngFor let-event [ngForOf]="resultData" let-in="index">
                    <tr *ngIf="(in > 0 && resultData[in - 1]?.isArchived === false && event.isArchived === true) || (in === 0 && event.isArchived === true)" class="hidden lg:flex flex-col flex-no-wrap h-6 border-b border-gray-300 lg:table-row mb-2 lg:mb-0"><td class="bg-gray-300 text-primary text-xs font-light text-center" colspan="7">ARCHIVED</td></tr>
                    <tr class="hidden lg:flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                        <td class="max-w-xxxs text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            <div class="flex w-9 h-9 mx-auto btn-light-gray-gradient rounded-full">
                                <div [attr.title]="(event.firstName || '') + ' ' + (event.lastName || '')" *ngIf="organisationUsers[event.createdById].styleNum" class="flex w-full h-full font-medium rounded-full items-center justify-center cursor-default" [ngClass]="'user-icon' + ('-' + organisationUsers[event.createdById]?.styleNum || '')">
                                    {{event.firstName?.slice(0,1)}}{{event.lastName?.slice(0, 1)}}
                                </div>
                                <img [attr.title]="(event.firstName || '') + ' ' + (event.lastName || '')" *ngIf="organisationUsers[event.createdById].imageFile" [src]="organisationUsers[event.createdById].imageFile" class="flex w-full h-full rounded-full items-center justify-center cursor-default">
                            </div>
                        </td>
                        <td class="initial-table-coloumn text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            {{event.contractName?.split('--')[0]}}
                        </td>
                        <td class="initial-table-coloumn text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            {{event.eventName ? event.eventName : 'NA'}}
                        </td>
                        <td class="text-sm border-r border-gray-300 pl-3 py-1 lg:py-2">
                            <div class="flex flex-wrap min-width-weathers items-center justify-start">
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('rain')" (click)="onClickReportGo(event.eventId, 'rain')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Rain" [type]="'rain'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('wind')" (click)="onClickReportGo(event.eventId, 'wind')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Wind" [type]="'wind'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('temperature')" (click)="onClickReportGo(event.eventId, 'temperature')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Temperature" [type]="'temperature'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('snow')" (click)="onClickReportGo(event.eventId, 'snow')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Snow" [type]="'snow'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('hail')" (click)="onClickReportGo(event.eventId, 'hail')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Hail" [type]="'hail'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('storm')" (click)="onClickReportGo(event.eventId, 'storm')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Storm" [type]="'storm'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('dust')" (click)="onClickReportGo(event.eventId, 'dust')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Dust" [type]="'dust'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('fog')" (click)="onClickReportGo(event.eventId, 'fog')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Fog" [type]="'fog'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('tornado')" (click)="onClickReportGo(event.eventId, 'tornado')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Tornado" [type]="'tornado'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('hurricane')" (click)="onClickReportGo(event.eventId, 'hurricane')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Tropical Cyclone" [type]="'hurricane'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('earthquake')" (click)="onClickReportGo(event.eventId, 'earthquake')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Earthquake" [type]="'earthquake'"></app-weather-type-small>
                                    </div>
                                </div>
                                <div class="weather-icon-button" [ngClass]="{ 'noclick': event.isArchived }">
                                    <div *ngIf="event.selectedWeather.includes('wildfire')" (click)="onClickReportGo(event.eventId, 'wildfire')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                        <app-weather-type-small title="Wildfire" [type]="'wildfire'"></app-weather-type-small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-sm border-r border-gray-300 px-4 py-1 lg:py-2">
                            <div class="flex flex-col xl:flex-row w-full h-full">
                                <div class="mb-1/2 xl:mb-0">{{event.Start_Date}}</div>
                                <div class="hidden xl:inline pt-2"><span class="circle-separator bg-orange-base rounded-full mx-2 flex"></span></div>
                                <div class="mt-1/2 xl:mt-0">{{event.End_Date}}</div>
                            </div>
                        </td>
                        <td class=" w-1/4 text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 max-w-xxs">
                            <div class="flex items-center">
                                <img *ngIf="event.isRegion" src="/assets/images/map-polygon-select-primary.png" class="w-3 h-3 mr-3 opacity-75">
                                <img *ngIf="!event.isRegion" src="/assets/images/map-pin-select-primary.png" class="w-4 h-4 mr-3 opacity-75">
                                <span class="flex-1">{{event.location}}</span>
                            </div>
                        </td>
                        <td class="actions-column min-w-xs max-w-xxxs text-xs xl:text-sm border-r border-gray-300 px-1 xl:px-2 py-1 lg:py-2">
                            <div *ngIf="!event.isArchived" class="flex flex-wrap min-width-actions items-center justify-center">
                                <div (click)="onClickReportGo(event.eventId)" href="javascript:void(0)" title="View" class="view-event-button flex flex-none w-8 h-8 mx-1 rounded-full btn-gradient items-center justify-center text-white font-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-5 h-5 py-px">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                    </svg>                                            
                                </div>
                                <div class="share-event-button" [ngClass]="{ 'noclick': !event.isOwnReport }">
                                    <div (click)="onClickShareReport(event)" title="Share" class="relative flex flex-none w-8 h-8 mx-1 rounded-full btn-gradient items-center justify-center text-white font-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-5 h-5 py-px">
                                            <path stroke-linecap="round" stroke-linejoin="round"  d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14"/>
                                        </svg>  
                                        <div *ngIf="event.isOwnReport && event.sharedWith && event.sharedWith.length > 0" class="share-icon absolute flex w-4 h-4 justify-center items-center bg-secondarydarker rounded-full z-10">
                                            <span class="text-white font-medium">{{ event.sharedWith.length }}</span>
                                        </div>                                
                                    </div>  
                                </div>
                                <div class="archive-event-button" [ngClass]="{ 'noclick': !event.isOwnReport }">
                                    <div (click)="onClickArchiveReport(event)" title="Archive" class="flex flex-none w-8 h-8 mx-1 rounded-full btn-gray-gradient items-center justify-center text-white font-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke-width="1.5" stroke="currentColor" class="mx-auto w-5 h-5 py-px">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M80,152V408a40.12,40.12,0,0,0,40,40H392a40.12,40.12,0,0,0,40-40V152" style="stroke-width:32px"/>
                                            <rect stroke-linecap="round" stroke-linejoin="round" x="48" y="64" width="416" height="80" rx="28" ry="28" style="stroke-width:32px"/>
                                            <polyline stroke-linecap="round" stroke-linejoin="round" points="320 304 256 368 192 304" style="stroke-width:32px"/>
                                            <line stroke-linecap="round" stroke-linejoin="round" x1="256" y1="345.89" x2="256" y2="224" style="stroke-width:32px"/>
                                        </svg>                                     
                                    </div>
                                </div>                              
                            </div>
                            <div *ngIf="event.isArchived" (click)="onClickUnarchiveReport(event)"  class="flex flex-wrap min-width-actions items-center justify-center">
                                <div href="javascript:void(0)" class="unarchive-event-button flex flex-none w-28 h-8 rounded-full items-center justify-center text-gray-600 hover:text-primary font-semibold cursor-pointer">
                                    Unarchive                                          
                                </div>
                            </div>
                        </td>
                    </tr>


                    <tr class="block lg:hidden border-b border-gray-300">
                        <td class="block text-sm p-3">
                            <div class="flex flex-wrap justify-start mb-2">
                                <div *ngIf="event.selectedWeather.includes('rain')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'rain'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('wind')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'wind'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('temperature')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'temperature'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('snow')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'snow'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('hail')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'hail'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('storm')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'storm'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('dust')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'dust'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('fog')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'fog'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('tornado')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'tornado'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('hurricane')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'hurricane'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('earthquake')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'earthquake'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('wildfire')" class="w-10 h-10 mr-2 mb-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'wildfire'"></app-weather-type-small>
                                </div>
                            </div>
                            <div class="mb-1 text-xs">
                                <span class="title-width">Name </span><strong class="ml-3 text-sm">{{event.eventName ? event.eventName : 'NA'}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                <span class="title-width">Id </span><strong class="ml-3 text-sm">{{event.contractName?.split('--')[0]}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                <span class="title-width">Location </span><strong class="ml-3 text-sm">{{event.location}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                <span class="title-width">Period </span><strong class="ml-3 text-sm">{{event.Start_Date}} - {{event.End_Date}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                <span class="title-width">Created By </span><strong class="ml-3 text-sm">{{event.firstName}} {{event.lastName}}</strong>
                            </div>
                            <div *ngIf="event.isOwnReport" class="flex justify-end">
                                <button (click)="onClickShareReport(event)" class="relative mr-2 px-2 items-center justify-center text-gray-600 hover:text-gray-800 font-medium cursor-pointer">
                                    Share
                                    <div *ngIf="event.isOwnReport && event.sharedWith && event.sharedWith.length > 0" class="share-icon-mobile absolute flex w-4 h-4 justify-center items-center bg-secondarydarker rounded-full z-10">
                                        <span class="text-white font-medium">{{ event.sharedWith.length }}</span>
                                    </div>                             
                                </button>  
                                <button (click)="onClickArchiveReport(event)"  class="ml-1 px-2 items-center justify-center text-gray-600 hover:text-gray-800 font-medium cursor-pointer">
                                    Archive                                    
                                </button> 
                            </div>
                            <div class="mt-2">
                                <button (click)="onClickReportGo(event.eventId)" class="bg-primary block rounded-full text-sm font-semibold text-white mb-3 px-3 py-2 w-full view-report">
                                    View report
                                </button>
                            </div>
                        </td>
                    </tr>

                </ng-template>
            </tbody>

            <tfoot class="footer-table bg-primary text-white">
                <tr class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                    <th class="footer-table__elem max-w-xxxs text-center text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">User</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Report Id</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Name</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Weather Types / Risks</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Date Range</th>
                    <th class="footer-table__elem w-1/4 text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Location</th>
                    <th class="footer-table__elem min-w-xs max-w-xxxs actions-column text-center text-white font-light border-r border-primary px-2 xl:px-3 py-1 lg:py-2">Actions</th>
                </tr>
            </tfoot>
        </table>




        <!-- Pagination -->

        <div class="flex justify-center w-full" *ngIf="resultData.length>0">
            <ul class="pager flex items-center justify-center">

                <li class="{{currentPage==1?'inactiveprevious':'activeprevious'}}" class="mr-3">
                    <a (click)="previous()" href="javascript:void(0)" class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
                    </a>
                </li>
                <li *ngFor="let page of pages;let i = index" class="mx-1">
                    <a (click)="onPage(page)" href="javascript:void(0)" class="{{(currentPage)==page?'opacity-75 cursor-default pointer-events-none':'font-semibold hover:text-gray-600'}}" class="block p-2">{{page}}</a>
                </li>
                <li class="{{currentPage==numOfPages?'inactivenext':'activenext'}}" class="ml-3">
                    <a (click)="next()" href="javascript:void(0)" class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Pagination -->




        <!-- Sharing -->

        <div *ngIf="accessPermissionsPopup" class="watchevent">
            <div class="watcheventwrp commonclr">
                <div class="my-2 mx-4 text-left">
                    <h2 class="mb-6 text-xl font-semibold">Sharing permissions</h2>
                    <div class="mt-2 mb-4"> 
                        <div class="flex text-primaryLighter">
                            <div class="flex justify-center items-center rounded-full bg-gray-500 w-7 h-7 mr-2 text-white">
                                <svg *ngIf="permissionObject.type=='r'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-5 h-5 py-1/2 px-1/2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                </svg>
                                <svg *ngIf="permissionObject.type=='p'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="mx-auto w-5 h-5 py-1/2 px-1/2">
                                    <path d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 0 0-3-3h-3.879a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H6a3 3 0 0 0-3 3v3.162A3.756 3.756 0 0 1 4.094 9h15.812ZM4.094 10.5a2.25 2.25 0 0 0-2.227 2.568l.857 6A2.25 2.25 0 0 0 4.951 21H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-2.227-2.568H4.094Z" />
                                </svg>
                            </div>
                            <span *ngIf="permissionObject.type=='r'" class="mt-1 text-gray-700 text-md font-semibold">
                                DPH{{ permissionObject.id }} 
                                {{ permissionObject.name ? '- ' + permissionObject.name : '' }}
                            </span>
                            <span *ngIf="permissionObject.type=='p'" class="mt-1 text-gray-700 text-md font-semibold">
                                PORTFOLIO
                            </span>
                        </div>
                    </div>
                        <div class="scrollable-panel">
                            <div *ngIf="reportAccessSearchList && reportAccessSearchList.length > 0">
                                <div class="mt-4 mb-1">
                                    <span class="text-xs font-medium text-gray-500">Grant access to</span>
                                </div>
                                <ul class="my-3 members-list">
                                    <li> 
                                        <label class="checkbox-container relative flex mt-2 pl-6 items-center cursor-pointer select-none">
                                            <input (click)="userAccessAddDontAdd('all', $event.target.checked)" [checked]="false" type="checkbox" class="absolute opacity-0 pointer"/>
                                            <span class="checkmark absolute top-1 left-0 w-4 h-4 border border-gray-500 rounded-sm"></span>
                                            Select All
                                        </label>
                                    </li>
                                    <li class="my-2" *ngFor="let user of reportAccessSearchList">
                                        <label class="checkbox-container relative flex mt-2 pl-6 text-md items-center cursor-pointer select-none">
                                            <input (click)="userAccessAddDontAdd(user)" [checked]="false" type="checkbox" class="absolute opacity-0 pointer" [(ngModel)]="user.checked"/>
                                            <span class="checkmark absolute top-1 left-0 w-4 h-4 border border-gray-500 rounded-sm transition-all duration-50 ease-in-out"></span>
                                            <span [ngClass]="{'font-medium': user.checked}">{{ user.firstName }} {{ user.lastName }}</span>
                                            <span class="ml-auto text-secondarydark font-medium text-sx"> {{ user.username }}</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="reportAccessUserList && reportAccessUserList.length > 0" class="my-3 border-b border-gray-400 divider"></div>
                            <div *ngIf="reportAccessUserList && reportAccessUserList.length > 0">
                                <div class="">
                                    <span class="my-1 text-xs font-medium text-gray-500">People with access</span>
                                </div>
                                <div class="access-list">
                                    <ul>
                                        <li class="my-2" *ngFor="let user of reportAccessUserList">
                                            <span *ngIf="organisationUsers[user.id].styleNum" class="inline-flex rounded-full font-medium w-7 h-7 mr-2 text-xs btn-light-gray-gradient justify-center items-center" [ngClass]="[user.revoke ? 'opacity-25' : '','user-icon' + ('-' + organisationUsers[user.id]?.styleNum || '')]">  
                                                {{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}
                                            </span>
                                            <img *ngIf="organisationUsers[user.id].imageFile" [src]="organisationUsers[user.id].imageFile" class="inline-flex rounded-full w-7 h-7 mr-2 items-center justify-center" [ngClass]="[user.revoke ? 'opacity-25' : '']">


                                            <span class="text-md" [ngClass]="{ 'opacity-25': user.revoke }">
                                                {{ user.firstName }} {{ user.lastName }}
                                            </span>
                                            <button (click)="userAccessRevokeRestore(user)" class="float-right mt-1/2 px-2 py-1/2 text-2xs text-center border border-gray-400 rounded-md">
                                                {{ !user.revoke ? 'REVOKE' : 'RESTORE' }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    
                </div>
                <div class="flex mt-6 py-2 px-4 justify-end items-center">
                    <button (click)="closeOverlay(); clearObjAndArr()" class="button-filter text-center border border-gray-400">Cancel</button>
                    <button *ngIf="accessRevokeList.length > 0 || accessAddList.length > 0" (click)="applyAccessPermissions()" class="button-filter text-white ml-4 text-center btn-gradient">Apply</button>                
                </div>
            </div>
        </div>


        <div *ngIf="accessPermissionsClosePopup" class="watchevent">
            <div class="watcheventwrp commonclr">
                <div class="my-2 mx-4 text-left">
                    <h2 class="mb-6 text-xl font-semibold">Sharing Permissions</h2>
                    <div class="mt-2 mb-4"> 
                        <div class="flex text-primaryLighter">
                            <span class="mt-1 text-gray-700 text-base font-normal" [innerHTML]="permissionObject.type=='r' ? 'Permissions have been updated for report <b class=\'font-medium\'> DHP' + permissionObject.id + '</b>': 'Your portfolio permissions have been updated'">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex mt-6 py-2 px-4 justify-end items-center">
                    <button (click)="closeOverlay(); clearObjAndArr()" class="button-filter text-center border border-gray-400">Close</button>             
                </div>
            </div>
        </div>


        <!-- Sharing -->




        <!-- Archiving -->


        <div *ngIf="archiveReportPopup" class="watchevent">
            <div class="watcheventwrp commonclr">
                <div class="my-2 mx-4 text-left">
                    <h2 class="mb-6 text-xl font-semibold">Archive</h2>
                    <div class="mt-2 mb-4"> 
                        <div class="flex flex-col text-primaryLighter">
                            <span class="mt-1 text-gray-700 text-base font-normal">You are about to archive report <b class="font-medium"> DPH{{ archiveObject.id }}</b></span>
                            <span class="mt-6 text-gray-700 text-base font-normal">Archived reports will be moved to the end of your portfolio</span>
                        </div>
                    </div>
                </div>
                <div class="flex mt-6 py-2 px-4 justify-end items-center">
                    <button (click)="closeOverlay(); clearObjAndArr()" class="button-filter text-center border border-gray-400">Cancel</button>
                    <button (click)="applyReportArchive()" class="button-filter ml-4 text-white text-center btn-gradient">Apply</button>                
                </div>
            </div>
        </div>


        <div *ngIf="unarchiveReportPopup" class="watchevent">
            <div class="watcheventwrp commonclr">
                <div class="my-2 mx-4 text-left">
                    <h2 class="mb-6 text-xl font-semibold">Unarchive</h2>
                    <div class="mt-2 mb-4"> 
                        <div class="flex flex-col text-primaryLighter">
                            <span class="mt-1 text-gray-700 text-base font-normal">Unarchive report <b class="font-medium"> DPH{{ archiveObject.id }}</b></span>
                        </div>
                    </div>
                </div>
                <div class="flex mt-6 py-2 px-4 justify-end items-center">
                    <button (click)="closeOverlay(); clearObjAndArr()" class="button-filter text-center border border-gray-400">Cancel</button>
                    <button (click)="applyReportArchive()" class="button-filter ml-4 text-white text-center btn-gradient">Apply</button>                
                </div>
            </div>
        </div>


        <div *ngIf="archiveReportClosePopup" class="watchevent">
            <div class="watcheventwrp commonclr">
                <div class="my-2 mx-4 text-left">
                    <h2 class="mb-6 text-xl font-semibold">Archive</h2>
                    <div class="mt-2 mb-4"> 
                        <div class="flex text-primaryLighter">
                            <span class="mt-1 text-gray-700 text-base font-normal">
                                <b class="font-medium">DHP{{ archiveObject.id }} </b> has been archived
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex mt-6 py-2 px-4 justify-end items-center">
                    <button (click)="closeOverlay(); clearObjAndArr()" class="button-filter text-center border border-gray-400">Close</button>             
                </div>
            </div>
        </div>


        <div *ngIf="unarchiveReportClosePopup" class="watchevent">
            <div class="watcheventwrp commonclr">
                <div class="my-2 mx-4 text-left">
                    <h2 class="mb-6 text-xl font-semibold">Unarchive</h2>
                    <div class="mt-2 mb-4"> 
                        <div class="flex text-primaryLighter">
                            <span class="mt-1 text-gray-700 text-base font-normal">
                                <b class="font-medium">DHP{{ archiveObject.id }} </b> has been unarchived
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex mt-6 py-2 px-4 justify-end items-center">
                    <button (click)="closeOverlay(); clearObjAndArr()" class="button-filter text-center border border-gray-400">Close</button>             
                </div>
            </div>
        </div>


        <!-- Archiving -->

    </div>
</div>


